@import url("https://fonts.cdnfonts.com/css/sf-pro-display");

.wrapper {
  margin: 43px 0;
  width: 992px;
  min-height: 400px;
  padding: 64px;
  background: #fff;
  font-family: "SF Pro Display", sans-serif;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.title {
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
}
.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  max-width: 478px;
}
.grey {
  color: #bdbdbd;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.blue {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #00538e;
}
.mainInfo {
  border-bottom: 2px solid #f2f2f2;
  margin-bottom: 16px;
}
.row {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin: 16px 0px;
}
.greyBlock {
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: #f4f5f6;
  padding: 10px;
  border-radius: 8px;
  box-sizing: border-box;
}
.mainInfo .greyBlock {
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: #f4f5f6;
  padding: 10px;
  border-radius: 8px;

  box-sizing: border-box;
}
.mainInfo .greyBlock:first-child {
  width: 124px;
}
.mainInfo .greyBlock:nth-child(3) {
  width: 70px;
}
.mainInfo .greyBlock:nth-child(4) {
  width: 268px;
}
.mainInfo .greyBlock:nth-child(5) {
  width: 268px;
}
.addInfo {
  display: flex;
  width: 100%;
  margin-bottom: 32px;
  border-bottom: 2px solid #f2f2f2;
}
.lkw {
  border-right: 2px solid #f2f2f2;
  width: 40%;
  margin-bottom: 10px;
}
.lkwInfo {
  position: relative;
  margin-right: 28px;
  padding-bottom: 6px;
}
.lkwInfo .row {
  width: 100%;
  margin: 4px 0;
}
.lkwInfo .row .greyBlock {
  padding-right: 5px;
  flex: 1;
}
.arbeiter {
  width: 60%;
  margin-left: 28px;
}
.arbeiterInfo {
  position: relative;
  padding-bottom: 6px;
}
.arbeiterInfo .row {
  width: 100%;
  margin: 4px 0;
}
.arbeiterInfo .row .greyBlock {
  padding-right: 0px;
  flex: 1;
}

.arbeiterInfo .row .greyBlock:first-child {
  min-width: 150px !important;
}

.arbeiterInfo .row .greyBlock:first-child p {
  width: 100%;
}
.titles {
  display: flex;
  gap: calc(100% - 78%);
  margin-bottom: 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.titles p:last-child {
  margin-left: -15 px;
}

.arbeiterInfo .titles {
  display: flex;
  gap: calc(100% - 75%);
  margin-bottom: 10px;
}

.arbeiterInfo .titles p:nth-child(3) {
  margin-left: -80px;
}

.arbeiterInfo .titles p:last-child {
  margin-left: -110px;
}

.footer-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.btn {
  padding: 16px 32px;
  border-radius: 10px;
  border: 1px solid #00538d;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.secondary-btn {
  color: #00538d;
}
.primary-btn {
  background: #00538d;
  color: #fff;
}
.btn-group {
  display: flex;
  gap: 16px;
}
.contact-group {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
}
.contact {
  cursor: copy;
}
.status {
  display: block;
  margin: 0 auto;
  margin-top: 100px;
  text-align: center;
}
.checkboxBlock {
  display: flex;
  gap: 12px;
}
.checkbox {
  accent-color: #00538e;
  height: max-content;
}
.link {
  color: #00538d;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
}
.disabled {
  pointer-events: none;
  background-color: #e9e9e9;
  color: #acacac;
  border-color: transparent;
}
.comment {
  position: relative;
}
.comment textarea {
  font-size: 14px;
  border: 1px solid #b0b0b0;
  height: 96px;
  width: 100%;
  resize: none;
  padding: 14px 10px 14px 16px;
  margin-bottom: 32px;
  border-radius: 10px;
}
.comment textarea::placeholder {
  color: #bdbdbd;
}
.comment label {
  position: absolute;
  font-size: 12px;
  top: -5px;
  left: 13px;
  background-color: #fff;
}
