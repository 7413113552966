:root {
  --header-height: 148px;
}

header {
  width: 100%;
  height: var(--header-height);
  padding: 40px 48px;
  display: flex;
  gap: 120px;
}

header .awards {
  display: flex;
  gap: 12px;
}

main {
  min-height: calc(100vh - var(--header-height));
  width: 100%;
  background: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
}
