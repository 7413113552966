.FieldWrapper {
    padding: 18px 36px;
    background: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 32px;
}
.Field {
    display: flex;
    gap: 12px;
}
.Field input {
    padding: 8px 16px;
    border-radius: 8px;
    flex: 1 1 auto;
    border: 1px solid #00538e;
    font-size: 32px;
    color: #333;
}
.fieldBtn {
    padding: 8px 16px;
    border-radius: 8px;
    color: #fff;
    background-color: #00538e;
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}
