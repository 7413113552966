.wrapper {
  width: 992px;
  min-height: 400px;
  padding: 64px;
  background: #fff;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 0 16px;
}
.title {
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
}
.number {
  color: #00538e;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
}
.data-list {
  width: 100%;
  margin-bottom: 32px;
}
.list-item {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 2px solid #f2f2f2;
}
.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  max-width: 478px;
}
.item-value {
  color: #bdbdbd;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.pdf-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 32px;
}

.footer-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.btn {
  padding: 16px 32px;
  border-radius: 10px;
  border: 1px solid #00538d;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
}
.secondary-btn {
  color: #00538d;
}
.primary-btn {
  background: #00538d;
  color: #fff;
}
.btn-group {
  display: flex;
  gap: 16px;
}
.contact-group {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
}
.contact {
  cursor: copy;
}
.status {
  display: block;
  margin: 0 auto;
  margin-top: 100px;
  text-align: center;
}
.checkboxBlock {
  display: flex;
  gap: 12px;
}
.checkbox {
  accent-color: #00538e;
  height: max-content;
}
.link {
  color: #00538d;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
}
.disabled {
  pointer-events: none;
  background-color: #e9e9e9;
  color: #acacac;
  border-color: transparent;
}
.blueText {
  color: #00538d;
  line-height: 20px;
  font-style: normal;
  font-weight: 400;
}
